import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  massPetitionPage: null,
};

export const getMassPetitionPage = createAsyncThunk(
  "masspetitions/getMassPetitionPage",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/masspetition/page`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const massPetitionPageSlice = createSlice({
  name: "masspetitions",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getMassPetitionPage.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMassPetitionPage.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.massPetitionPage = action.payload?.data;
    },
    [getMassPetitionPage.rejected]: (state, action) => {
      state.status = "failed";
      state.massPetitionPage = action.payload?.data;
      state.message = action.payload?.message;
    },

  },
});

export default massPetitionPageSlice.reducer;
