import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import menuSlicePrePersist from "./slices/menuSlice";
import websiteSlicePrePersist from "./slices/websiteSlice";
import homeBannerSlicePrePersist from "./slices/homeBannerSlice";
import comingUpScheduleSlice from "./slices/comingUpScheduleSlice";
import testimonialSlice from "./slices/testimonialSlice";
import footerSlicePrePersist from "./slices/footerSlice";
import partnersSlicePrePersist from "./slices/partnersSlice";
import programCategorySlice from "./slices/programCategorySlice";
import programListSlice from "./slices/programListSlice";
import prayerRequestPageSlice from "./slices/prayerRequestPageSlice";
import countryListPrePersist from "./slices/countrySlice";
import stateListPrePersist from "./slices/stateSlice";
import articlePrePersist from "./slices/articleSlice";
import fullScheduleSlice from "./slices/fullScheduleSlice";
import dailyMassSlice from "./slices/dailyMassSlice";
import saintDaySlice from "./slices/saintDaySlice";
import otherDetailsPrePersist from "./slices/otherDetailsSlice";
import wordSaintSlice from "./slices/wordSaintSlice";
import adBannerSlice from "./slices/adBannerSlice";
import featuredProgramSlice from "./slices/featuredProgramSlice";
import liveScheduleSlice from "./slices/liveScheduleSlice";
import massPetitionTimingSlice from "./slices/massPetitionTimingSlice";
import massPetitionsSlice from "./slices/massPetitionsSlice";
import massPetitionPageSlice from "./slices/massPetitionPageSlice";
import holymassCounterSlice from "./slices/holymassCounterSlice";
import seoSlice from "./slices/seoSlice";
import featuredTestimonialsSlice from "./slices/featuredTestimonialsSlice";
import testimonialsData from "./slices/testmonialsSlice";
import partnersPage from "./slices/PrayerPartnerPageSlice";
// import prayerCampaignData from "./slices/prayerCampaignSlice";
import campaignData from "./slices/campaignSlice";
import search from "./slices/SearchSlice";
import aboutSlice from "./slices/aboutSlice";
import termsSlice from "./slices/termsSlice";
import privacySlice from "./slices/privacySlice";
import contact from "./slices/contactSlice";
import searchDetails from "./slices/searchDetailSlice";
import prayersPage from "./slices/prayersPageSlice";
import relatedPrayerData from "./slices/relatedPrayerSlice";
import prayerData from "./slices/prayerSlice";
import searchPrayerData from "./slices/searchPrayerSlice";
import schedulePage from "./slices/schedulePageSlice";
import dailyScheduleSlice from "./slices/dailyScheduleSlice";
import watchon from "./slices/watchonSlice";
import retreatData from "./slices/retreatSlice";
import homeData from "./slices/homeSlice";

localStorage.removeItem('persist:menuv3');

const menuSlice = persistReducer({ key: "menuv5", storage }, menuSlicePrePersist);
const websiteSlice = persistReducer(
  { key: "websitev5", storage },
  websiteSlicePrePersist
);

const footerSlice = persistReducer(
  { key: "footerv4", storage },
  footerSlicePrePersist
);
const partnersSlice = persistReducer(
  { key: "partnersv3", storage },
  partnersSlicePrePersist
);
const countrySlice = persistReducer(
  { key: "countryv3", storage },
  countryListPrePersist
);
const stateSlice = persistReducer(
  { key: "stateListv3", storage },
  stateListPrePersist
);
const articleSlice = persistReducer(
  { key: "articlev3", storage },
  articlePrePersist
);
const otherDetailsSlice = persistReducer(
  { key: "otherdetailsv3", storage },
  otherDetailsPrePersist
);

const rootReducer = combineReducers({
  menu: menuSlice,
  website: websiteSlice,
  homeBanner: homeBannerSlicePrePersist,
  comingUpSchedule: comingUpScheduleSlice,
  testimonial: testimonialSlice,
  footer: footerSlice,
  partner: partnersSlice,
  programCategory: programCategorySlice,
  programList: programListSlice,
  fullSchedule: fullScheduleSlice,
  dailyMass: dailyMassSlice,
  requestPage: prayerRequestPageSlice,
  country: countrySlice,
  stateList: stateSlice,
  article: articleSlice,
  saint: saintDaySlice,
  otherdetails: otherDetailsSlice,
  wordSaint: wordSaintSlice,
  adbanner: adBannerSlice,
  featureprogram: featuredProgramSlice,
  liveSchedule: liveScheduleSlice,
  massTiming: massPetitionTimingSlice,
  massPetitions: massPetitionsSlice,
  massPetitionPage: massPetitionPageSlice,
  holymassCounter: holymassCounterSlice,
  seo: seoSlice,
  featuretestimonials: featuredTestimonialsSlice,
  testimonialsData: testimonialsData,
  partnersPage: partnersPage,
  campaignData: campaignData,
  // prayerCampaignData: prayerCampaignData,
  homeData: homeData,
  search: search,
  about: aboutSlice,
  terms: termsSlice,
  privacy: privacySlice,
  contact: contact,
  searchDetails: searchDetails,
  prayersPage: prayersPage,
  relatedPrayerData: relatedPrayerData,
  prayerData: prayerData,
  searchPrayerData: searchPrayerData,
  watchon: watchon,
  schedulePage: schedulePage,
  dailySchedule: dailyScheduleSlice,
  retreatData: retreatData,

});

export default rootReducer;
