import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  testimonialData: {
    pages: null,
    status: null,
    testimonial: [],
    total: null,
  },
};

export const getTestimonialData = createAsyncThunk(
  "testimonial/getTestimonialData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      return await api.get(`/v1/testimonials?perpage=10&page=${params}`);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {},
  extraReducers: {
    [getTestimonialData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getTestimonialData.fulfilled]: (state, action) => {
      //state.status = "succeeded";
      if (state.testimonialData.pages > 1) {
        let newData = state.testimonialData.testimonial.concat(
          action.payload.data.testimonial
        );
        state.testimonialData.testimonial = newData;
      } else {
        state.testimonialData = action.payload?.data;
      }
    },
    [getTestimonialData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default testimonialSlice.reducer;
