import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  prayerData: null,
};

export const getPrayerData = createAsyncThunk(
  "prayer/getPrayerData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/prayer/list`, { params });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postViewCount = createAsyncThunk(
  "prayer/viewcount",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`v1/prayer/viewcount?id=${params}`);
      response.id = params;
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const prayerSlice = createSlice({
  name: "prayer",
  initialState,
  reducers: {},
  extraReducers: {

    [getPrayerData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getPrayerData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.prayerData = action.payload?.data;
    },
    [getPrayerData.rejected]: (state, action) => {
      state.status = "failed";
      if (action.payload?.data.length !== 0) {
        state.prayerData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    [postViewCount.pending]: (state, action) => {
      state.status = "loading";
      state.message = action.payload?.message;
    },
    [postViewCount.fulfilled]: (state, action) => {
      let array2 = [...state.prayerData.prayerList]
      array2.find(a => a.id === action?.payload?.id).views = action?.payload?.data?.prayerViewCount;
      state.prayerData = { ...state.prayerData, prayerList: array2 };
      state.status = "succeeded";
      state.message = action.payload?.message;
    },
    [postViewCount.rejected]: (state, action) => {
      state.status = "failed";
      state.message = action.payload?.message;
    },

  },
});

export default prayerSlice.reducer;
