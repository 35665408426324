import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  searchResult: null,
  totalPage: null,
};

export const fetchSearchData = createAsyncThunk(
  "search",
  async ({ searchTerm, page }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/search?s=${searchTerm}&page=${page}&perpage=10&type=all`
      );
      return { result: response, page: page };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSearchData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [fetchSearchData.fulfilled]: (state, action) => {
      state.status = "succeeded";

      if (action.payload.page > 1) {
        let Data = state.searchResult.concat(action.payload.result.data.result);
        state.searchResult = Data;
      } else {
        state.searchResult = action.payload?.result?.data?.result;
        state.totalPage = action.payload?.result?.data;
      }
    },
    [fetchSearchData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.searchResult = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default searchSlice.reducer;
