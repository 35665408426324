import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  menuData: null,
  expires_at: "",
};

export const getMenuData = createAsyncThunk(
  "menu/getMenuData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/headermenus`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getMenuData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMenuData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.menuData = action.payload?.data;
      state.expires_at = new Date().getTime();
    },
    [getMenuData.rejected]: (state, action) => {
      state.status = "failed";
      state.expires_at = null;
      if (action.payload?.data.length !== 0) {
        state.menuData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});

export default menuSlice.reducer;
