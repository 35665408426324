import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  holymassCounterData: null,
};

export const getHolymassCounterData = createAsyncThunk(
  "holymasscounter/getHolymassCounterData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/watchlive/timer`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const holymassCounterSlice = createSlice({
  name: "holymasscounter",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getHolymassCounterData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getHolymassCounterData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.holymassCounterData = action.payload?.data;
    },
    [getHolymassCounterData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.holymassCounterData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    
  },
});

export default holymassCounterSlice.reducer;
