import React from 'react';
import styles from './CommonLoader.module.scss';
import loaderLogo from '../../assets/images/logo_SW_Prayer.svg'
const CommonLoader = () => {
    return (
        <div className={styles.loaderWrap}>
            <img src={loaderLogo} alt='SW Prayer'/>
        </div>
    )
}

export default CommonLoader;