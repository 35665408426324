import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/common.scss'
import CommonLoader from '../util/CommonLoader'
import ScrollToTop from './ScrollToTop'
import styles from './App.module.scss'
const HomePage = lazy(() => import('../pages/Home'))
const TestimonialPage = lazy(() => import('../pages/Testimonial'))
const PrayerRequestPage = lazy(() => import('../pages/PrayerRequestMain'))
const PrayerPartnersPage = lazy(() => import('../pages/PrayerPartners'))
const AboutPage = lazy(() => import('../pages/About'))
const MassPetitionPage = lazy(() => import('../pages/MassPetition'))
const ProgramsPage = lazy(() => import('../pages/Programs'))
const ProgramInnerPage = lazy(() => import('../pages/ProgramInner'))
const WatchLivePage = lazy(() => import('../pages/WatchLive'))
const DailyMassPage = lazy(() => import('../pages/DailyMass'))
const StyleGuidelines = lazy(() => import('../pages/StyleGuidelines'))
const SearchResult = lazy(() => import('../pages/SearchResult'))
const PrayerSearchResults = lazy(() => import('../pages/PrayerSearchResults'))
const ContactPage = lazy(() => import('../pages/Contact'))
const PrayersPage = lazy(() => import('../pages/Prayers'))
const SchedulePage = lazy(() => import('../pages/Schedule'))
const WatchonPage = lazy(() => import('../pages/Watchon'))
const PrayerDetailPage = lazy(() => import('../pages/PrayerDetail'))
const SearchDetails = lazy(() => import('../pages/SearchDetails'))
const PrivacyPage = lazy(() => import('../pages/PrivacyPage'))
const TermsPage = lazy(() => import('../pages/TermsPage'))
const RetreatPage = lazy(() => import('../pages/Retreat'))
const PageNotFound = lazy(() => import("../pages/PageNotFound"));

const App = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Router>
          <Suspense
            fallback={
              <div>
                <CommonLoader />
              </div>
            }
          >
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/prayer-campaign" component={HomePage} />
              <Route path="/word-for-the-day" component={HomePage} />
              <Route path="/saint-of-the-day" component={HomePage} />
              <Route exact path="/programs" component={ProgramsPage}></Route>
              <Route exact path="/programs/:catId?" component={ProgramsPage} />
              <Route exact path="/retreats/:catId?" component={ProgramsPage} />

              <Route
                exact
                path="/prayer-request"
                component={PrayerRequestPage}
              ></Route>
              <Route
                exact
                path="/program/:programId"
                component={ProgramInnerPage}
              />
              <Route exact path="/mass-petition" component={MassPetitionPage} />
              <Route exact path="/watch-live" component={WatchLivePage} />
              <Route
                exact
                path="/watch-live/:fullschedule"
                component={WatchLivePage}
              ></Route>
              <Route
                exact
                path="/testimonial"
                component={TestimonialPage}
              ></Route>
              <Route
                exact
                path="/search-details"
                component={SearchDetails}
              ></Route>
              <Route
                exact
                path="/prayer-partners"
                component={PrayerPartnersPage}
              ></Route>
              <Route exact path="/about" component={AboutPage}></Route>

              <Route
                exact
                path="/style-guidelines"
                component={StyleGuidelines}
              ></Route>
              <Route exact path="/daily-mass" component={DailyMassPage} />
              <Route exact path="/searchResult" component={SearchResult} />
              <Route
                exact
                path="/searchResult/:searchTerm"
                component={SearchResult}
              />
              <Route exact path="/contact" component={ContactPage} />

              {/* <Route path="/prayers/:prayerId?" component={PrayersPage} /> */}

              <Route exact path="/prayers" component={PrayersPage} />

              <Route
                exact
                path="/prayer/:prayerSlug/"
                component={PrayerDetailPage}
              />
              <Route exact path="/prayers/:prayerId" component={PrayersPage} />
              <Route
                exact
                path="/prayer-results/:searchTerm"
                component={PrayerSearchResults}
              />
              <Route
                exact
                path="/prayer/:prayerSlug/:prayerId"
                component={PrayerDetailPage}
              />
              <Route exact path="/watchon" component={WatchonPage} />
              <Route
                path="/watchon/:tab/:platform?"
                component={WatchonPage}
              ></Route>
              <Route exact path="/schedules" component={SchedulePage} />
              <Route exact path="/connectedtv" component={WatchonPage} />

              <Route exact path="/privacy-policy" component={PrivacyPage} />
              {/* Temp fixes */}
              <Route exact path="/privacystatement" component={PrivacyPage} />
              <Route exact path="/privacy-statement" component={PrivacyPage} />
              <Route exact path="/terms-and-conditions" component={TermsPage} />
              <Route exact path="/termsandconditions" component={TermsPage} />
              <Route
                exact
                path="/retreat/:retreatId"
                component={RetreatPage}
              />

              {/* <Route
                exact
                path="/retreat/:retreatId/:seourl"
                component={RetreatPage}
              /> */}

              <Route component={PageNotFound} />

            </Switch>
          </Suspense>
        </Router>
      </div>
    </div>
  )
}

export default App
