import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  partnerPageData: null,
};

export const getPartnersPageData = createAsyncThunk(
  "prayerpartnerspage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/prayerpartnerspage`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const partnersPageSlice = createSlice({
  name: "partnersPage",
  initialState,
  reducers: {},
  extraReducers: {
    [getPartnersPageData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getPartnersPageData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.partnerPageData = action.payload?.data;
    },
    [getPartnersPageData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.partnerPageData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default partnersPageSlice.reducer;
