import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  fullScheduleData: null,
};

export const getFullScheduleData = createAsyncThunk(
  "fullSchedule/getFullScheduleData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/watchlive/schedule`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const fullScheduleSlice = createSlice({
  name: "fullSchedule",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getFullScheduleData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFullScheduleData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.fullScheduleData = action.payload?.data;
    },
    [getFullScheduleData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.fullScheduleData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    
  },
});

export default fullScheduleSlice.reducer;
