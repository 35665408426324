import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  websiteData: null,
  expires_at: "",
};

export const getWebsiteData = createAsyncThunk(
  "website/getWebsiteData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/websites`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getWebsiteData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getWebsiteData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.websiteData = action.payload?.data;
      state.expires_at = new Date().getTime()
    },
    [getWebsiteData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.websiteData = action.payload?.data;
      }
      state.message = action.payload?.message;
      state.expires_at = null;
    },

  },
});

export default websiteSlice.reducer;
