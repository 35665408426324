import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  otherdetailsData: null,
  expires_at: '',
};

export const getOtherdetailsData = createAsyncThunk(
  "otherdetails/getOtherdetailsData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/otherdetails`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const otherDetailsSlice = createSlice({
  name: "otherdetails",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getOtherdetailsData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getOtherdetailsData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.otherdetailsData = action.payload?.data;
      state.expires_at = new Date().getTime()
    },
    [getOtherdetailsData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.otherdetailsData = action.payload?.data;
      }
      state.message = action.payload?.message;
      state.expires_at = null;
    },

  },
});

export default otherDetailsSlice.reducer;
