import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  searchDetails: null,
};

export const getSearchDetails = createAsyncThunk(
  "search/Details",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      // const response = await api.get(`/v1/post/single?url=${params}`);
      const response = await api.get(`/v1/episode/single?url=${params}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const SearchDetailSlice = createSlice({
  name: "searchDetails",
  initialState,
  reducers: {},
  extraReducers: {
    [getSearchDetails.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSearchDetails.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.searchDetails = action.payload?.data;
    },
    [getSearchDetails.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.searchDetails = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default SearchDetailSlice.reducer;
