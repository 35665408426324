import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  partnersData: null,
  expires_at: "",
};

export const getPartnersData = createAsyncThunk(
  "partners/getPartnersData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/prayerpartners`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getPartnersData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getPartnersData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.partnersData = action.payload?.data;
      state.expires_at = new Date().getTime()
    },
    [getPartnersData.rejected]: (state, action) => {
      state.status = "failed";
      if (action.payload?.data.length !== 0) {
        state.partnersData = action.payload?.data;
      }
      state.message = action.payload?.message;
      state.expires_at = null;
    },

  },
});

export default partnersSlice.reducer;
