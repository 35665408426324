import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  aboutData: null,
};

export const getAboutData = createAsyncThunk(
  "aboutpage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/aboutpage`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchMoreEndorsements = createAsyncThunk(
  "aboutpageEndorsement",
  async ({ keyItem, page }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/endorsements?region=${keyItem}&page=${page}`
      );
      return { response: response.data, keyItem };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers: {
    [getAboutData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAboutData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.aboutData = action.payload?.data;
    },
    [getAboutData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.aboutData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    [fetchMoreEndorsements.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [fetchMoreEndorsements.fulfilled]: (state, action) => {
      state.status = "succeeded";

      let MoreData = {
        ...state.aboutData,
        endorsement: {
          endorsements: {
            ...state.aboutData.endorsement.endorsements,
            [action.payload?.keyItem]: {
              ...state.aboutData.endorsement?.endorsements[
                action.payload?.keyItem
              ],
              endorsement: [
                ...state.aboutData.endorsement.endorsements[
                  action.payload?.keyItem
                ].endorsement,
                ...action.payload.response.endorsements,
              ],
            },
          },
        },
      };
      state.aboutData = MoreData;
    },
    [fetchMoreEndorsements.rejected]: (state, action) => {
      state.status = "failed";

      // if (action.payload?.data.length !== 0) {
      //   state.aboutData = action.payload?.data;
      // }
      // state.message = action.payload?.message;
    },
  },
});

export default aboutSlice.reducer;
