import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  comingUpScheduleData: null,
};

export const getComingUpScheduleData = createAsyncThunk(
  "comingUpSchedule/getComingUpScheduleData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/homebannerschedule`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const comingUpScheduleSlice = createSlice({
  name: "comingUpSchedule",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getComingUpScheduleData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getComingUpScheduleData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.comingUpScheduleData = action.payload?.data;
    },
    [getComingUpScheduleData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.comingUpScheduleData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    
  },
});

export default comingUpScheduleSlice.reducer;
